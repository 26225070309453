import request from '@/utils/request'


export function summary() {
  return request({
    url: '/screen/summary',
    method: 'post'
  })
}
export function volActivityRank() {
  return request({
    url: '/screen/volActivityRank',
    method: 'post'
  })
}
export function activityRankByCategory() {
  return request({
    url: '/screen/activityRankByCategory',
    method: 'post'
  })
}
export function volActivityRankByCategory() {
  return request({
    url: '/screen/volActivityRankByCategory',
    method: 'post'
  })
}
export function activityRank() {
  return request({
    url: '/screen/activityRank',
    method: 'post'
  })
}
export function activityPersons() {
  return request({
    url: '/screen/activityPersons',
    method: 'post'
  })
}
export function volActivityPersons() {
  return request({
    url: '/screen/volActivityPersons',
    method: 'post'
  })
}
export function activityCompare() {
  return request({
    url: '/screen/activityCompare',
    method: 'post'
  })
}
export function volActivityCompare() {
  return request({
    url: '/screen/volActivityCompare',
    method: 'post'
  })
}
export function exchangePointRank() {
  return request({
    url: '/screen/exchangePointRank',
    method: 'post'
  })
}
export function shopPointsRank(params) {
  return request({
    url: '/screen/shopPointsRank',
    method: 'post',
    params
  })
}