<template>
	<div class="dapingbox">
		<div class="dingbu">
			<div class="return" @click="toshouye()">返回首页</div>
			<p class="nowtime">{{time}}</p>
		</div>
		<div class="topbox">
			<div class="left">
				<p class="title">游客人数</p>
				<div class="zhucenum">
					<div v-for="(item,index) in zhuce.split('')" :key="index" class="num">{{item}}</div>
					<div class="ren">人</div>
				</div>
				<div class="perbox">
					<div :style="{ width: percent + '%' }"></div>
				</div>
				<div class="renshu">
					<p class="zhiyuan">社员人数 <span>{{zhiyuan}}人</span></p>
					<p class="shiming">实名用户数 <span>{{shiming}}人</span></p>
				</div>
			</div>
			<div class="center">
                <div class="pai02" style="margin-right: 26px;">
                  <p>社员服务场次 <span>{{voltimes}}</span>场</p>
                  <p>社员服务参与人数 <span>{{voljoinusers}}</span>人</p>
                </div>
				<div class="pai01" style="margin-right: 26px;">
					<p>普通活动场次 <span>{{acttimes}}</span>场</p>
					<p>活动参与人数 <span>{{actjoinusers}}</span>人</p>
				</div>
				<div class="pai03">
					<p>最近一月新增用户数 <span>{{recentmonthusers}}</span>人</p>
					<p>昨日新增用户数 <span>{{yestodayusers}}</span>人</p>
				</div>
			</div>
			<div class="right">
				<div class="item">
					<div class="leftitem">
						<p class="num">{{toalpoints}}</p>
						<p class="title">总发放积分数</p>
					</div>
					<div class="rightitem">
						<p class="num">{{totalusedpoints}}</p>
						<p class="title">总消耗积分数</p>
					</div>
				</div>
				<div class="item">
					<div class="leftitem">
						<p class="num">{{lastmonthincomepoints}}</p>
						<p class="title">最近1月发放积分数</p>
					</div>
					<div class="rightitem">
						<p class="num">{{lastmonthconsumepoints}}</p>
						<p class="title">最近1月消耗积分数</p>
					</div>
				</div>
			</div>
		</div>
		<div class="huatubox">
			<div class="leftbox">
				<div class="leftbox1">
					<div id="leftbox1"></div>
					<div id="leftbox2"></div>
				</div>
				<div class="leftbox3">
					<div id="leftbox3"></div>
				</div>
			</div>
			<div class="centerbox">
				<div id="centerbox1"></div>
				<div id="centerbox2"></div>
				<div class="bottom">
					<div class="center3box">
						<div id="centerbox3"></div>
					</div>
					<div class="center4box">
						<div id="centerbox4"></div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="rightbox1">
					<div id="rightbox1"></div>
				</div>
				<div class="rightbox2">
					<div id="rightbox2"></div>
				</div>
				<div class="rightbox3">
					<div class="more" @click="shopSaleRank">查看更多</div>
					<div id="rightbox3">

					</div>
					<div class="right3list">
						<div v-for="(item,index) in right3list" :key="index">{{item.name}}:{{item.points}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="activitydistribution" v-if="activitydistributionShowFlag">
			<div class="close" @click="closeCustomClose"><i class="el-icon-circle-close"></i></div>
			<div class="th">
				<div class="td">社区</div>
				<div class="td">活动数</div>
			</div>
			<div class="tr" v-for="item in activitydistributionList" :key="item.name">
				<div class="td">{{ item.name }}</div>
				<div class="td">{{ item.acttimes }} （{{ item.percent }}）</div>
			</div>
		</div>

		<div class="activitydistribution" v-if="dialogTableVisible">
			<div class="close" @click="closeCustomClose"><i class="el-icon-circle-close"></i></div>
			<div class="th">
				<div class="td">商户名称</div>
				<div class="td">消费金额</div>
			</div>
			<div class="tr" v-for="(item,index) in shopPointsRankList" :key="index">
				<div class="td" :title="item.name">{{ item.name }}</div>
				<div class="td">{{ item.points }} 积分</div>
			</div>
			<el-pagination
				:pager-count="3"
				@size-change="handleSizeChange"
				background
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalRow">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		summary,
		volActivityRank,
		activityRankByCategory,
		volActivityRankByCategory,
		activityRank,
		activityPersons,
		volActivityPersons,
		activityCompare,
		volActivityCompare,
		exchangePointRank,
		shopPointsRank
	} from '@/api/daping.js'
	import top1 from '../assets/imgs/daping/top1.jpg'
	import top2 from '../assets/imgs/daping/top2.jpg'
	import top3 from '../assets/imgs/daping/top3.jpg'
	import top4 from '../assets/imgs/daping/top4.jpg'
	import top5 from '../assets/imgs/daping/top5.jpg'
	export default {
		data() {
			return {
				time: "",
				zhuce: '',
				zhiyuan: '',
				shiming: '',
				percent: '',
				lastmonthconsumepoints: '',
				lastmonthincomepoints: '',
				toalpoints: '',
				totalusedpoints: '',
				actjoinusers: '',
				acttimes: '',
				recentmonthusers: '',
				voljoinusers: '',
				voltimes: '',
				yestodayusers: '',
				right3list: [],
				activitydistributionShowFlag: false,
				activitydistributionList: [],
				currentPage: 1,
				pageSize: 10,
				totalRow: 0,
				shopPointsRankList: [],
				dialogTableVisible: false
			};
		},
		mounted() {
			let that = this;
			that.time = that.getnowtime();
			setInterval(function() {
				that.time = that.getnowtime();
			}, 1000);
			//基础数据
			summary().then(res => {
				if (res.result == 200) {
					var detail = res.detail;
					that.lastmonthconsumepoints = detail.lastmonthconsumepoints;
					that.lastmonthincomepoints = detail.lastmonthincomepoints;
					that.toalpoints = detail.toalpoints;
					that.totalusedpoints = detail.totalusedpoints;
					that.actjoinusers = detail.actjoinusers;
					that.acttimes = detail.acttimes;
					that.shiming = detail.realusers;
					that.recentmonthusers = detail.recentmonthusers;

					let totalusers = detail.totalusers + '';
					let total = 6;
					let zeronum = total - totalusers.length;
					let zero = '';
					for (let i = 0; i < zeronum; i++) {
						zero = zero + '0';
					}
					that.zhuce = zero + totalusers;
					that.voljoinusers = detail.voljoinusers;
					that.voltimes = detail.voltimes;
					that.zhiyuan = detail.volunteers;
					that.yestodayusers = detail.yestodayusers;
					that.percent = that.shiming == 0 ? 0 : ((that.zhiyuan / that.shiming) * 100);
				} else {
					that.$message.error(res.description);
				}
			})
			//普通活动分布
			activityRank().then(res => {
				if (res.result == 200) {
					// console.log(res.detail)
					that.left1chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//社员服务排名
			volActivityRank().then(res => {
				if (res.result == 200) {
					that.left3chart(res.detail.reverse());
				} else {
					that.$message.error(res.description);
				}
			})
			//普通活动类型和社员服务类型
			activityRankByCategory().then(res => {
				if (res.result == 200) {
					let list1 = res.detail;
					volActivityRankByCategory().then(res2 => {
						if (res2.result == 200) {
							let list2 = res2.detail;
							that.left2chart(list1, list2);
						} else {
							that.$message.error(res2.description);
						}
					})
				} else {
					that.$message.error(res.description);
				}
			})
			//普通活动参与人数
			activityPersons().then(res => {
				if (res.result == 200) {
					that.center3chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//社员任务与人数
			volActivityPersons().then(res => {
				if (res.result == 200) {
					that.center4chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//普通活动同比
			activityCompare().then(res => {
				if (res.result == 200) {
					that.center1chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//社员任务同比
			volActivityCompare().then(res => {
				if (res.result == 200) {
					that.center2chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//积分收入与支出、积分发放排名
			exchangePointRank().then(res => {
				if (res.result == 200) {
					that.right1chart(res.detail);
					that.right2chart(res.detail);
				} else {
					that.$message.error(res.description);
				}
			})
			//商户消费排行榜
			shopPointsRank({
				page: 1,
				pageSize: 5
			}).then(res => {
				if (res.result == 200) {
					let detail = res.detail.list;
					that.right3chart(detail.reverse());
				} else {
					that.$message.error(res.description);
				}
			})
			
		},
		methods: {
			//商户消费排行榜
			shopSaleRank(){
				shopPointsRank({
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.result == 200) {
						this.dialogTableVisible = true;
						this.shopPointsRankList = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.shopSaleRank();
      },
      handleCurrentChange(val) {
				this.currentPage = val;
				this.shopSaleRank();
      },
			left1chart(list) {
				let sum = 0;
				list.map(item=>{
					sum += item.acttimes;
				})
				//计算百分比
				list.map(item=>{
					item.percent = ((item.acttimes/sum)*100).toFixed(2) + '%'
				})
				let that = this;
				that.activitydistributionList = list;
				let chart = that.$echarts.init(document.getElementById('leftbox1'));
				let shuju = list.map((item) => {
					return {
						name: item.name.slice(0,2),
						value: item.acttimes
					}
				});
				let optionData = {
					title: {
						text: '活动分布',
						left: '10px',
						top: '10px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					color: ['#4b7efe', '#0566e8', '#02a7a1', '#ffbf75', '#917cff', '#fb7e4f'],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					legend: { // 图例
						textStyle: {
							color: "#ffffff"
						},
						align: 'left', // 设置图例位置
						left: 75, // 调整图例距离右边的距离
						top: 'center',
						orient: "vertical"
					},
					series: [{
						name: '活动分布',
						type: 'pie',
						radius: [25, 80],
						center: ['75%', '50%'],
						roseType: 'radius',

						label: {
							show: false
						},
						emphasis: {
							label: {
								show: false
							}
						},
						data: shuju
					}, ]
				};
				chart.setOption(optionData);
				chart.getZr().on('click', e=> {
					if(!e.target){
						this.activitydistributionShowFlag = true;
					}
				});
			},
			//关闭自定义弹出框
			closeCustomClose(){
				this.activitydistributionShowFlag = false;
				this.currentPage = 1;
				this.pageSize = 10;
				this.dialogTableVisible = false;
			},
			left2chart(list1, list2) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('leftbox2'));
				let legend1 = list1.map((item) => {
					return item.name
				});
				let series1 = list1.map((item) => {
					return {
						name: item.name,
						value: item.acttimes
					}
				});
				let legend2 = list2.map((item) => {
					return item.name
				});

				let series2 = list2.map((item) => {
					return {
						name: item.name,
						value: item.acttimes
					}
				});
				let optionData = {
					color: ['#9240d5', '#3f046f', '#640cab', '#7773d9', '#534ed9', '#0b0b87', '#333086', '#1f1ab2',
						'#ffce55', '#fff200', '#e0bf00', '#ffc127', '#ff983b', '#f98435', '#ff632c',
					],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					title: [{
							text: '普通活动类型',
							right: '200',
							top: '0',
							textStyle: {
								color: '#ffffff',
								fontSize: 15
							},
						},
						{
							text: '社员任务类型',
							right: '83',
							top: '0',
							textStyle: {
								color: '#ffffff',
								fontSize: 15
							},
						}
					],
					legend: [{ // 图例
							textStyle: {
								color: "#ffffff"
							},
							itemGap: 6,
							align: 'left', // 设置图例位置
							right: 180, // 调整图例距离右边的距离
							top: '25px',
							orient: "vertical",
							itemHeight: 9, //图例图标的高度
							itemWidth: 9, //图例图标的宽度
							data: legend1
						},
						{ // 图例
							textStyle: {
								color: "#ffffff",
							},
							itemGap: 4,
							align: 'left', // 设置图例位置
							right: 10, // 调整图例距离右边的距离
							top: '25px',
							orient: "vertical",
							itemHeight: 9, //图例图标的高度
							itemWidth: 9, //图例图标的宽度
							data: legend2
						}
					],
					series: [{
						name: '普通活动类型',
						type: 'pie',
						radius: ['40%', '55%'],
						center: ['23%', '50%'],
						label: {
							show: false
						},
						emphasis: {
							label: {
								show: false
							}
						},
						data: series1
					}, {
						name: '社员任务类型',
						type: 'pie',
						radius: '30%',
						center: ['23%', '50%'],
						label: {
							show: false
						},
						emphasis: {
							label: {
								show: false
							}
						},
						data: series2
					}, ]
				};
				chart.setOption(optionData);
			},
			left3chart(list) {
				let that = this;
				let xdata = list.map((item) => {
					return item.name.slice(0,2)
				});
				let ydata = list.map((item) => {
					return item.acttimes
				});
				let chart = that.$echarts.init(document.getElementById('leftbox3'));
				let optionData = {
					title: {
						text: '社员任务排名',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '50px',
						right: '50px',
						top: '50px',
						bottom: '40px',
					},

					xAxis: {
						inverse: false,
						splitArea: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#1bd0ec',

							}
						},
						splitLine: {
							lineStyle: {
								color: "#0e4569",
								type: 'dashed'
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					yAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1bd0ec'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					series: [{
						name: '社员任务次数',
						type: 'bar',
						barWidth: 14, //柱图宽度
						label: {
							// 柱图头部显示值
							show: true,
							position: "right",
							color: "#99f4ff",
							fontSize: "14px",
							formatter: (params) => {
								return params.value[params.encode.x[0]];
							},
						},
						showBackground: true, //柱状背景
						backgroundStyle: { //背景颜色，加透明度
							color: '#093d55'
						},
						data: ydata,
						itemStyle: {
							//柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
							normal: {
								//柱形图圆角，初始化效果
								color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#40cfe5" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#3bf8c5" // 100% 处的颜色
								}], false)
							}
						},
					}, ]
				};
				chart.setOption(optionData);
			},
			right1chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('centerbox1'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata = list.map((item) => {
					return item.incomepoints;
				});
				let optionData = {
					title: {
						text: '积分发放排名',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					grid: {
						left: '1%',
						right: '1%',
						top: '80px',
						bottom: '40px',
					},
					xAxis: {
						type: 'category',
						data: xdata,
						axisLine: {
							show: false, //隐藏y轴
						},
						axisTick: {
							show: false, //刻度线
						},
						splitLine: { //分割线配置
							show: false,
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '14px',
							}
						},
					},
					yAxis: {
						show: false,
						type: 'value',
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							show: false, //隐藏y轴
						},
						axisLabel: {
							show: false, //隐藏刻度值
						},
					},
					series: [{
						data: ydata,
						type: 'bar',
						label: {
							// 柱图头部显示值
							show: true,
							position: "top",
							color: "#ffffff",
							fontSize: "12px",
							formatter: (params) => {
								return params.value[params.encode.x[0]];
							},
						},
						barWidth: 25, //柱图宽度
						itemStyle: {
							//柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
							normal: {
								//柱形图圆角，初始化效果
								barBorderRadius: [15, 15, 15, 15],
								color: that.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: "#0068a0" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#2bdbff" // 100% 处的颜色
								}], false)
							}
						},
					}]
				};
				chart.setOption(optionData);
			},
			right2chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('centerbox2'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata1 = list.map((item) => {
					return item.incomepoints;
				});
				let ydata2 = list.map((item) => {
					return 0 - item.costpoints;
				});
				let optionData = {
					color: ['#00b17d', '#d39500'],
					title: {
						text: '积分收入与支出',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow',
							
						},
						formatter: (p) => {
							// 你可以在这里对你不满意的数据进行处理，
							// itme.marker: 这个是你看见默认的图标
							return [
								p[0].axisValue,
								...p.map(item => `${item.marker}${item.seriesName}：${Math.abs(item.data)}`)
							].join('<br />')
						},
					},
					grid: {
						left: '55px',
						right: '10px',
						top: '70px',
						bottom: '40px',
					},
					legend: { // 图例
						data: [{
							name: '收入',
							textStyle: {
								color: '#00b17d'
							}
						},{
                          name: '支出',
                          textStyle: {
                            color: '#d39500'
                          }
                        }], // 图例 记得和series中的name要和这里保持一致
						align: 'left', // 设置图例位置
						right: 10, // 调整图例距离右边的距离
						top: 40
					},
					xAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1191d0'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '14px',
							}
						},
					},
					yAxis: {
						inverse: false,
						splitArea: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: "#0b4a7e"
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '10px',
							},
							formatter: (value) => {
								return Math.abs(value)
							}
						},
					},
					series: [{
							name: '收入',
							type: 'bar',
							//  都为正就是堆叠柱状图，一个为正一个为负则为双向柱状图
							stack: 'one',
							data: ydata2,
							barWidth: 22, //柱图宽度
						},
						{
							name: '支出',
							type: 'bar',
							barWidth: 25, //柱图宽度
							stack: 'one',
							label: {
								formatter: (value) => {
									return Math.abs(value)
								}
							},
							data: ydata1
						},
					]
				};
				chart.setOption(optionData);
			},
			right3chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('rightbox3'));
				let data = list.map((item) => {
					return item.points;
				});
				let xList2 = list.map((item) => {
					return item.name;
				});
				that.right3list = list.reverse();
				// let data = [100, 110, 120, 130, 150];
				let xList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
				const COLOR_ARRAY = {
					chart_3: {
						color_1: ['#455052', '#455052', '#455052', '#037eb7', '#705d00'],
						color_2: ['#84b6c1', '#84b6c1', '#84b6c1', '#26b0ef', '#ca9700'],
					}
				};
				let maxValue = data[4];
				let max = (maxValue + '').split('');
				let changdu = max.length - 1;
				let num = (Number(max[0]) + 1) + '';
				for(let i = 0;i<changdu;i++){
					num = num + '0';
				}
				const grayBar = data.map(() => {
					return Number(num);
				});
				
				const vStyleList = data.map((item, index) => {
					let i = index;
					let itemStyle = {
						color: new that.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 1,
								color: COLOR_ARRAY.chart_3.color_1[i]
							},
							{
								offset: 0,
								color: COLOR_ARRAY.chart_3.color_2[i]
							},
						]),
					};
					const data = {
						value: item,
						itemStyle
					};
					return data;
				});

				let optionData = {
					title: {
						text: '商户消费排行榜（当月TOP5）',
						left: '0px',
						top: '10px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					grid: {
						left: '50px',
						right: '10px',
						top: '40px',
						bottom: '10px',
					},
					xAxis: {
						show: false,
						type: 'value',
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							show: false, //隐藏y轴
						},
						axisLabel: {
							show: false, //隐藏刻度值
						},
					},
					yAxis: {
						type: 'category',
						data: xList,
						axisLine: {
							show: false, //隐藏y轴
						},
						axisTick: {
							show: false, //刻度线
						},
						splitLine: { //分割线配置
							show: false,
						},
						renderAsImage: true,
						axisLabel: { //x轴文字的配置
							show: true,
							formatter: function(value) {

								return '{' + value + '| }';

							},

							rich: {
								value: {
									lineHeight: 30,
									align: 'center'
								},
								Mon: {
									width: 21,
									height: 21,
									align: 'center',
									backgroundColor: {
										image: top5
									}
								},
								Tue: {
									width: 21,
									height: 21,
									align: 'center',
									backgroundColor: {
										image: top4
									}
								},
								Wed: {
									width: 21,
									height: 21,
									align: 'center',
									backgroundColor: {
										image: top3
									}
								},
								Thu: {
									width: 21,
									height: 21,
									align: 'center',
									backgroundColor: {
										image: top2
									}
								},
								Fri: {
									width: 21,
									height: 21,
									align: 'center',
									backgroundColor: {
										image: top1
									}
								},

							}
						},

					},
					series: [
						//背景色
						{
							show: true,
							type: "bar",
							barWidth: 12,
							backgroundStyle: { //背景颜色，加透明度
								color: '#fff'
							},
							itemStyle: {
								color: "rgba(13, 118, 93, 0.1)", //填充色
								borderColor: "#003431",
								borderWidth: 1,
							},
							z: 1,
							data: grayBar,
						},
						//蓝条
						{
							show: true,
							type: "bar",
							barGap: "-100%",
							barWidth: 12,
							showBackground: true, //柱状背景
							backgroundStyle: { //背景颜色，加透明度
								color: '#1b1e65'
							},
							z: -12,
							max: 1,
							data: vStyleList,
						},
						{
							name: "dotted",
							type: "pictorialBar",
							symbol: "rect",
							itemStyle: {
								color: "#08113c",
							},
							symbolRepeat: true,
							symbolSize: [4, 12],
							symbolMargin: 3,
							data: grayBar,
						},
					],
				};
				chart.setOption(optionData);
			},
			center1chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('rightbox1'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata1 = list.map((item) => {
					return item.currmonth;
				});
				let ydata2 = list.map((item) => {
					return item.lastmonth;
				});
				let optionData = {
					color: ['#08a8c5', '#05b789'],
					title: {
						text: '普通活动同比',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '30px',
						right: '20px',
						top: '80px',
						bottom: '40px',
					},
					legend: { // 图例
						textStyle: {
							color: "#19e4ff"
						},
						align: 'left', // 设置图例位置
						right: 10, // 调整图例距离右边的距离
						top: 40
					},
					xAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1bd0ec'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '9px',
							}
						},
					},
					yAxis: {
						splitArea: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#1bd0ec',

							}
						},
						splitLine: {
							lineStyle: {
								color: "#0e4569",
								type: 'dashed'
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					series: [{
							name: '本月',
							type: 'line',
							//  都为正就是堆叠柱状图，一个为正一个为负则为双向柱状图
							// stack: 'one',
							smooth: true,
							data: ydata1,
							itemStyle: {
								normal: {
									areaStyle: {
										type: 'default',
										//渐变色实现===
										color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
											//三种由深及浅的颜色
											[{
												offset: 0,
												color: '#00eaad'
											}, {
												offset: 1,
												color: '#080c34'
											}]),
									},
								},
							},
							// itemStyle: {
							// 	//柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
							// 	normal: {
							// 		//柱形图圆角，初始化效果
							// 		barBorderRadius: [15, 15,0,0],
							// 	}
							// },
						},
						{
							name: '上月',
							type: 'line',
							// stack: 'one',
							smooth: true,
							data: ydata2,
							itemStyle: {
								normal: {
									areaStyle: {
										type: 'default',
										//渐变色实现===
										color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
											//三种由深及浅的颜色
											[{
												offset: 0,
												color: '#09adc3'
											}, {
												offset: 1,
												color: '#080c34'
											}]),
									},
								},
							},
						},
					]
				};
				chart.setOption(optionData);
			},
			center2chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('rightbox2'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata1 = list.map((item) => {
					return item.currmonth;
				});
				let ydata2 = list.map((item) => {
					return item.lastmonth;
				});
				let optionData = {
					color: ['#f4d714', '#ffa206'],
					title: {
						text: '社员任务同比',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '30px',
						right: '20px',
						top: '80px',
						bottom: '40px',
					},
					legend: { // 图例
						textStyle: {
							color: "#19e4ff"
						},
						align: 'left', // 设置图例位置
						right: 10, // 调整图例距离右边的距离
						top: 40
					},
					xAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1bd0ec'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '9px',
							}
						},
					},
					yAxis: {
						splitArea: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#1bd0ec',

							}
						},
						splitLine: {
							lineStyle: {
								color: "#0e4569",
								type: 'dashed'
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					series: [{
							name: '本月',
							type: 'line',
							//  都为正就是堆叠柱状图，一个为正一个为负则为双向柱状图
							// stack: 'one',
							smooth: true,
							data: ydata1,
							itemStyle: {
								normal: {
									areaStyle: {
										type: 'default',
										//渐变色实现===
										color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
											//三种由深及浅的颜色
											[{
												offset: 0,
												color: '#ffde00'
											}, {
												offset: 1,
												color: '#080c36'
											}]),
									},
								},
							},
							// itemStyle: {
							// 	//柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
							// 	normal: {
							// 		//柱形图圆角，初始化效果
							// 		barBorderRadius: [15, 15,0,0],
							// 	}
							// },
						},
						{
							name: '上月',
							type: 'line',
							// stack: 'one',
							smooth: true,
							data: ydata2,
							itemStyle: {
								normal: {
									areaStyle: {
										type: 'default',
										//渐变色实现===
										color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
											//三种由深及浅的颜色
											[{
												offset: 0,
												color: '#ff7747'
											}, {
												offset: 1,
												color: '#080c36'
											}]),
									},
								},
							},
						},
					]
				};
				chart.setOption(optionData);
			},
			center3chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('centerbox3'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata1 = list.map((item) => {
					return item.nums;
				});
				let ydata2 = list.map((item) => {
					return item.joinnums;
				});
				let ydata3 = list.map((item) => {
					return item.viewcount;
				});
				let optionData = {
					color: ['#5ad5ab', '#6395f9', '#277ef6'],
					title: {
						text: '普通活动参与人数',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '80px',
						right: '10px',
						top: '80px',
						bottom: '40px',
					},
					legend: { // 图例
						textStyle: {
							color: "#16d0eb"
						},
						align: 'left', // 设置图例位置
						left: 'center', // 调整图例距离右边的距离
						top: 40
					},
					xAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1191d0'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '10px',
							}
						},
					},
					yAxis: {
						inverse: false,
						splitArea: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: "#0b4a7e"
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					series: [{
							name: '可参与人数',
							type: 'bar',
							stack: 'one',
							data: ydata1,
							barWidth: 30, //柱图宽度
						},
						{
							name: '报名人数',
							type: 'bar',
							stack: 'one',
							data: ydata2,
							barWidth: 30, //柱图宽度
						},
						{
							name: '浏览数',
							type: 'bar',
							stack: 'one',
							data: ydata3,
							barWidth: 30, //柱图宽度
						},
					]
				};
				chart.setOption(optionData);
			},
			center4chart(list) {
				let that = this;
				let chart = that.$echarts.init(document.getElementById('centerbox4'));
				let xdata = list.map((item) => {
					return item.name.slice(0,2);
				});
				let ydata1 = list.map((item) => {
					return item.nums;
				});
				let ydata2 = list.map((item) => {
					return item.joinnums;
				});
				let optionData = {
					color: ['#f8403b', '#f89663', '#f55c28'],
					title: {
						text: '社员任务参与人数',
						left: '14px',
						top: '14px',
						textStyle: {
							color: '#ffffff',
							fontSize: 15
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '65px',
						right: '10px',
						top: '80px',
						bottom: '40px',
					},
					legend: { // 图例
						textStyle: {
							color: "#16d0eb"
						},
						align: 'left', // 设置图例位置
						left: 'center', // 调整图例距离右边的距离
						top: 40
					},
					xAxis: {
						data: xdata, // x轴数据
						axisTick: {
							show: false, //刻度线
						},
						axisLine: {
							lineStyle: {
								color: '#1191d0'
							}
						},
						axisLabel: { //x轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '10px',
							}
						},
					},
					yAxis: {
						inverse: false,
						splitArea: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: "#0b4a7e"
							}
						},
						axisLabel: { //y轴文字的配置
							show: true,
							textStyle: {
								color: "#ffffff",
								fontSize: '12px',
							}
						},
					},
					series: [{
							name: '可参与人数',
							type: 'bar',
							//  都为正就是堆叠柱状图，一个为正一个为负则为双向柱状图
							stack: 'one',
							data: ydata1,
							barWidth: 30, //柱图宽度
							// itemStyle: {
							// 	//柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
							// 	normal: {
							// 		//柱形图圆角，初始化效果
							// 		barBorderRadius: [15, 15,0,0],
							// 	}
							// },
						},
						{
							name: '报名人数',
							type: 'bar',
							stack: 'one',
							data: ydata2,
							barWidth: 30, //柱图宽度
						},
					]
				};
				chart.setOption(optionData);
			},
			toshouye() {
				this.$router.push({
					path: "/index"
				})
			},
			getnowtime() {
				let a = new Date();
				let year = a.getFullYear();
				let mon = a.getMonth() + 1;
				let month = mon > 9 ? mon : '0' + mon;
				let day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
				let hour = a.getHours() > 9 ? a.getHours() : '0' + a.getHours();
				let min = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes();
				let s = a.getSeconds() > 9 ? a.getSeconds() : '0' + a.getSeconds();
				let week = a.getDay();
				let str = '';
				if (week == 0) {
					str = "星期日";
				} else if (week == 1) {
					str = "星期一";
				} else if (week == 2) {
					str = "星期二";
				} else if (week == 3) {
					str = "星期三";
				} else if (week == 4) {
					str = "星期四";
				} else if (week == 5) {
					str = "星期五";
				} else if (week == 6) {
					str = "星期六";
				}
				return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s + ' | ' + str;
			},
		},
	};
</script>

<style scoped lang="scss">
	.dapingbox {
		position: relative;
		width: 1920px;
		height: 1080px;
		box-sizing: border-box;
		background-image: url(../assets/imgs/daping/dapingbg.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding: 0 30px;
		overflow: hidden;

		.dingbu {
			overflow: hidden;
			height: 88px;

			.nowtime {
				float: right;
				line-height: 88px;
				font-size: 12px;
				color: #26a2df;
				font-weight: 700;
			}

			.return {
				float: left;
				height: 30px;
				line-height: 30px;
				margin-top: 26px;
				padding-left: 21px;
				background-image: url(../assets/imgs/daping/shouye.png);
				background-size: 16px 15px;
				background-repeat: no-repeat;
				background-position: left 7px;
				font-size: 12px;
				color: #26a2df;
				font-weight: 700;
				cursor: pointer;
			}
		}

		.huatubox {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.leftbox {
				width: 550px;

				.leftbox1 {
					background-size: cover;
					background-image: url(../assets/imgs/daping/left1chart.png);
					background-position: center;
					background-repeat: no-repeat;
					margin-bottom: 3px;

					#leftbox1 {
						height: 185px;
					}

					#leftbox2 {
						height: 245px;
					}
				}

				.leftbox3 {
					background-size: cover;
					background-image: url(../assets/imgs/daping/left2chart.png);
					background-position: center;
					background-repeat: no-repeat;

					#leftbox3 {
						height: 351px;
					}
				}
			}

			.rightbox {
				width: 406px;

				.rightbox1 {
					background-size: cover;
					background-image: url(../assets/imgs/daping/right1chart.png);
					background-position: center;
					background-repeat: no-repeat;
					margin-bottom: 7px;

					#rightbox1 {
						height: 246px;
					}
				}

				.rightbox2 {
					background-size: cover;
					background-image: url(../assets/imgs/daping/right2chart.png);
					background-position: center;
					background-repeat: no-repeat;
					margin-bottom: 5px;

					#rightbox2 {
						height: 276px;
					}
				}

				.rightbox3 {
					background-size: cover;
					background-image: url(../assets/imgs/daping/right3chart.png);
					background-position: center;
					background-repeat: no-repeat;
					position: relative;
					.more{
						position: absolute;
						right: 5px;
						top: 10px;
						color: #ffffff;
						font-size: 14px;
						font-weight: bold;
						z-index: 100;
						cursor: pointer;
						&:hover{
							color: #90ffff;
						}
					}

					#rightbox3 {
						position: relative;
						height: 249px;
						z-index: 10;
					}

					.right3list {
						position: absolute;
						right: 15px;
						top: 35px;
						color: #90FFFF;
						font-size: 12px;
						text-align: right;

						div {
							margin-bottom: 24px;
						}
					}
				}
			}

			.centerbox {
				width: 880px;

				#centerbox1 {
					height: 265px;
				}

				#centerbox2 {
					height: 265px;
				}

				.bottom {
					display: flex;
					justify-content: space-between;

					.center3box {
						width: 505px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url(../assets/imgs/daping/center1chart.png);

						#centerbox3 {
							height: 254px;
						}
					}

					.center4box {
						width: 505px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url(../assets/imgs/daping/center1chart.png);

						#centerbox4 {
							height: 254px;
						}
					}
				}
			}
		}

		.topbox {
			padding: 8px 0 13px;
			box-sizing: border-box;
			background-color: #0a1642;
			border: 2px solid #091a56;
			border-radius: 5px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;

			.right {
				padding-top: 18px;
				width: 388px;

				.item {

					overflow: hidden;
					background-image: url(../assets/imgs/daping/fenge.png);
					background-size: 2px 52px;
					background-position: center;
					background-repeat: no-repeat;

					div {
						float: left;
						width: 50%;
						box-sizing: border-box;
						padding-left: 83px;
						padding-top: 10px;
						height: 73px;
						background-size: 43px 45px;
						background-repeat: no-repeat;
						background-position: 25px 14px;

						.num {
							font-size: 22px;
						}

						.title {
							font-size: 12px;
							color: #18dbf6;
						}
					}

					.leftitem {
						background-image: url(../assets/imgs/daping/fafang.png);

						.num {
							color: #18dbf6;
						}
					}

					.rightitem {
						background-image: url(../assets/imgs/daping/xiaohao.png);

						.num {
							color: #f8ac11;
						}
					}
				}
			}

			.left {
				width: 388px;

				.title {
					height: 36px;
					line-height: 36px;
					font-size: 16px;
					color: #19e4ff;
				}

				.zhucenum {
					display: flex;
					justify-content: space-between;

					div {
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						height: 66px;
						line-height: 66px;
						text-align: center;
						font-size: 24px;
						font-weight: 700;
						color: #72e9ff;
					}

					.num {
						width: 53px;
						background-image: url(../assets/imgs/daping/num.png);
					}

					.ren {
						width: 46px;
						background-image: url(../assets/imgs/daping/ren.png);
					}

				}

				.perbox {
					margin-top: 16px;
					height: 20px;
					background-image: linear-gradient(to right, #193557, #2084bb);

					div {
						height: 20px;
						background-image: linear-gradient(to right, #1e467e, #3ccdee);
					}
				}

				.renshu {
					height: 28px;
					line-height: 28px;
					display: flex;
					justify-content: space-between;

					p {
						color: #fff;
						font-size: 15px;

						span {
							color: #43b0ed;
						}
					}
				}
			}

			.center {
				overflow: hidden;
				padding-top: 18px;

				.pai01 {
					background-image: url(../assets/imgs/daping/pai01.png);
				}

				.pai02 {
					background-image: url(../assets/imgs/daping/pai02.png);
				}

				.pai03 {
					background-image: url(../assets/imgs/daping/pai03.png);
				}

				div {
					float: left;
					width: 308px;
					height: 146px;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					box-sizing: border-box;
					padding-left: 27px;
					padding-top: 20px;

					p {
						font-size: 18px;
						color: #fff;
						line-height: 45px;

						span {
							font-size: 24px;
							font-weight: 700;
						}
					}
				}
			}
		}
		.activitydistribution{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 700px;
			min-height: 550px;
			max-height: 600px;
			overflow: auto;
			background: #13284f;
			color: #fff;
			border: 2px solid #365183;
			border-radius: 6px;
			padding: 20px 30px;
			box-sizing: border-box;
			box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.2);
			.el-pagination{
				margin-top: 10px;
			}
			::v-deep .el-pagination__jump,
			::v-deep .el-pagination__total{
				color: #fff !important;
			}
			.close{
				position: absolute;
				right: 10px;
				top: 10px;
				color: #ffffff;
				font-size: 20px;
				cursor: pointer;
				&:hover{
					color: #b1e5fd;
				}
			}

			.tr,.th{
				display: flex;
				line-height: 2.6em;
				border-bottom: 1px solid #365183;
				&:last-child{
					border-bottom: 0 none;
				}
				.td{
					width: 50%;
					text-align: center;
					font-size: 14px;
					color: #b1e5fd;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
			}
			.th{
				font-weight: bold;
				.td{
					font-size: 16px;
					color: #ffffff;
				}
			}
			
		}
	}
</style>
